export const PHONE_COUNTRY_CODES = [
  {
    country: 'at',
    code: 43
  },
  {
    country: 'be',
    code: 32
  },
  {
    country: 'bg',
    code: 359
  },
  {
    country: 'hr',
    code: 385
  },
  {
    country: 'cy',
    code: 357
  },
  {
    country: 'cz',
    code: 420
  },
  {
    country: 'dk',
    code: 45
  },
  {
    country: 'et',
    code: 372
  },
  {
    country: 'fi',
    code: 358
  },
  {
    country: 'de',
    code: 49
  },
  {
    country: 'gr',
    code: 30
  },
  {
    country: 'hu',
    code: 36
  },
  {
    country: 'ic',
    code: 354
  },
  {
    country: 'ir',
    code: 353
  },
  {
    country: 'it',
    code: 39
  },
  {
    country: 'lv',
    code: 371
  },
  {
    country: 'li',
    code: 423
  },
  {
    country: 'lt',
    code: 370
  },
  {
    country: 'lx',
    code: 352
  },
  {
    country: 'mt',
    code: 356
  },
  {
    country: 'nl',
    code: 31
  },
  {
    country: 'no',
    code: 47
  },
  {
    country: 'pl',
    code: 48
  },
  {
    country: 'pt',
    code: 351
  },
  {
    country: 'ro',
    code: 40
  },
  {
    country: 'sk',
    code: 421
  },
  {
    country: 'si',
    code: 386
  },
  {
    country: 'es',
    code: 34
  },
  {
    country: 'se',
    code: 46
  },
  {
    country: 'ua',
    code: 380
  }
]

export const AP_SURVEY_QUESTIONS = {
  q1: {
    question: 'Daroval/a jste již někdy plasmu?',
    answer: undefined,
  },
  q1z1: {
    question: 'Kdy naposled?',
    answer: undefined
  },
  q1z2: {
    question: 'Kolikrát?',
    answer: undefined
  },
  q2: {
    question: 'Byl/a jste v posledních 4 týdnech očkován/a včetně orálních očkování, proti dětské obrně?',
    answer: undefined
  },
  q3: {
    question: 'Byl/a jste delší čas v lékařském ošetření?',
    answer: undefined
  },
  q3z1: {
    question: 'Proč?',
    answer: undefined
  },
  q3z2: {
    question: 'Kdy?',
    answer: undefined
  },
  q4: {
    question: 'Prodělal/a jste někdy operace?',
    answer: undefined
  },
  q4z1: {
    question: 'Proč?',
    answer: undefined
  },
  q4z2: {
    question: 'Kdy poslední?',
    answer: undefined
  },
  q5: {
    question: 'Absolvoval/a jste v posledních 7 dnech ošetření u zubního lékaře?',
    answer: undefined
  },
  q6: {
    question: 'Podstoupil/a jste piercing, tetování, permanentní make-up nebo techniky spojené s narušením kůže (skarifikace), náušnice?',
    answer: undefined
  },
  q6z: {
    question: 'Kdy naposledy?',
    answer: undefined
  },
  q7: {
    question: 'Dostal/a jste někdy transfuzi?',
    answer: undefined
  },
  q7z1: {
    question: 'Proč?',
    answer: undefined
  },
  q7z2: {
    question: 'Kdy?',
    answer: undefined
  },
  q8: {
    question: 'Měl/a jste někdy těžký úraz?',
    answer: undefined
  },
  q8z: {
    question: 'Jak dlouho jste byl/a v bezvědomí?',
    answer: undefined
  },
  q9: {
    question: 'Byl Vám zaveden v posledních 6 měsících katetr nebo endoskop?',
    answer: undefined
  },
  q10: {
    question: 'Jste alergický/á?',
    answer: undefined
  },
  q10z: {
    question: 'Na co jste alergický/á?',
    answer: undefined
  },
  q11: {
    question: 'Užíváte trvale léky?',
    answer: undefined
  },
  q11z1: {
    question: 'Které?',
    answer: undefined
  },
  q11z2: {
    question: 'Proč?',
    answer: undefined
  },
  q12: {
    question: 'Užíval/a jste někdy následující léky?',
    answer: undefined
  },
  q12a: {
    question: 'Akné (Accutane, Roaccutan)',
    answer: undefined
  },
  q12b: {
    question: 'Vypadávání vlasů, zvětšení prostaty (Proscar, Propecia)',
    answer: undefined
  },
  q12c: {
    question: 'Lupénka (Tigason, Tegison)',
    answer: undefined
  },
  q12d: {
    question: 'Lék Erivedge',
    answer: undefined
  },
  q13: {
    question: 'Podstoupil/a jste někdy akupunkturu?',
    answer: undefined
  },
  q13z: {
    question: 'Kdy naposled?',
    answer: undefined
  },
  q14: {
    question: 'Pozorujete v poslední době váhový ubytek?',
    answer: undefined
  },
  q15: {
    question: 'Užívala jste někdy humánní hypofízové nebo růstové hormony?',
    answer: undefined
  },
  q16: {
    question: 'Obdržel/a jste transplantát (Transplantát rohovky, tvrdé pleny mozkové)?',
    answer: undefined
  },
  q17: {
    question: 'Zranil/a jste se někdy použitou jehlou nebo nástroji znečištěnými krví?',
    answer: undefined
  },
  q18: {
    question: 'Užíval/a jste někdy nitrožilně drogy?',
    answer: undefined
  },
  q19: {
    question: 'Měl/a jste někdy nechráněný styk s rizikovými osobami (HIV, prostitutka)',
    answer: undefined
  },
  q19z: {
    question: 'Kdy naposled?',
    answer: undefined
  },
  q20: {
    question: 'Máte/Měla jste někdy kontakt jako (nebo s) prostituka(ou)',
    answer: undefined
  },
  q21: {
    question: 'Střídáte často sexuální partnery?',
    answer: undefined
  },
  q22: {
    question: 'Muži: Měl jste někdy pohlavní styk s muži?',
    answer: undefined
  },
  q23: {
    question: 'Byl/a jste v letech 1980-1996 ve Velké Británii nebo podrobil/a jste se tam operaci nebo dostal/a krevní transfuzi?',
    answer: undefined
  },
  q24: {
    question: 'Byl/a jste v posledních 6 měsících mimo území ČR?',
    answer: undefined
  },
  q24z: {
    question: 'Kde?',
    answer: undefined
  },
  q25: {
    question: 'Byl/a jste v posledních 6 měsících ve vazbě?',
    answer: undefined
  },
  q26: {
    question: 'Trpěl/a jste nebo trpíte následujícími poruchami nebo obtížemi?',
    answer: undefined
  },
  q26a: {
    question: 'Ztráta vědomí, meningitis, příznaky ochrnutí',
    answer: undefined
  },
  q26b: {
    question: 'Epileptické záchvaty',
    answer: undefined
  },
  q26c: {
    question: 'Creutzfeld-Jakobova nemoc',
    answer: undefined
  },
  q26d: {
    question: 'Creutzfeld-Jakobova nemoc ve Vaší rodině',
    answer: undefined
  },
  q26e: {
    question: 'Psychiatrícké oněmocnění, změny osobnosti',
    answer: undefined
  },
  q26f: {
    question: 'Maniodepresivní či úzkostné stavy, pokus o sebevraždu',
    answer: undefined
  },
  q26g: {
    question: 'Stavy úzkosti',
    answer: undefined
  },
  q26i: {
    question: 'Cévní onemocnění, srdeční vady, embolie, infarkt, vysoký krevní tlak',
    answer: undefined
  },
  q26j: {
    question: 'Zhoubné nádory',
    answer: undefined
  },
  q26k: {
    question: 'Tuberkulosa, astma, zánět pohrudnice, chronický kašel',
    answer: undefined
  },
  q26l: {
    question: 'Tyfus, onemocnění žaludku a střev',
    answer: undefined
  },
  q26m: {
    question: 'Onemocnění močových cest a ledvin',
    answer: undefined
  },
  q26n: {
    question: 'Syfilis, kapavka',
    answer: undefined
  },
  q26o: {
    question: 'Žloutenka nebo jiné onemocnění jater',
    answer: undefined
  },
  q26p: {
    question: 'Malárie nebo jiná tropická onemocnění',
    answer: undefined
  },
  q26q: {
    question: 'Jiné nakažlivé onemocnění',
    answer: undefined
  },
  q26r: {
    question: 'Jiná infekční a horečnatá onemocnění',
    answer: undefined
  },
  q26rz: {
    question: 'Byla infekce covidem 19 ověřena PCR testem?',
    answer: undefined
  },
  q26s: {
    question: 'Toxoplasmosa, infekční mononukleosa, rickettsiosa, bruceloza, borreliosa, babesiosa, SARS, parovirus B19',
    answer: undefined
  },
  q26t: {
    question: 'Onemocnění štítné žlázy nebo slinivky břišní',
    answer: undefined
  },
  q26u: {
    question: 'Časté krvácení z dásní a nosu, podlitiny, cévní trombózy',
    answer: undefined
  },
  q26v: {
    question: 'Poruchy krvetvorby',
    answer: undefined
  },
  q26w: {
    question: 'Kožní onemocnění',
    answer: undefined
  },
  q26x: {
    question: 'Poruchy látkové výmeny, např. cukrovka',
    answer: undefined
  },
  q26y: {
    question: 'Zánětlivý revmatizmus např. polyartritida nebo jiné autoimunitní onemocnění',
    answer: undefined
  },
  q27: {
    question: 'Užíváte',
    answer: undefined
  },
  q27a: {
    question: 'Alkohol',
    answer: undefined
  },
  q27az: {
    question: 'Jak často užíváte?',
    answer: undefined
  },
  q27b: {
    question: 'Nikotin',
    answer: undefined
  },
  q27bz: {
    question: 'Jak často užíváte?',
    answer: undefined
  },
  q27c: {
    question: 'Jiné drogy',
    answer: undefined
  },
  q27cz: {
    question: 'Jak často užíváte?',
    answer: undefined
  },
  q28: {
    question: 'Strava běžná?',
    answer: undefined
  },
  q28a: {
    question: 'Vegetariánská',
    answer: undefined
  },
  q28b: {
    question: 'Jiná',
    answer: undefined
  },
  q28z: {
    question: 'Jaká?',
    answer: undefined
  },
  q29: {
    question: 'Jiné sdělení lékaři',
    answer: undefined
  },
  q29z: {
    question: 'Jaké?',
    answer: undefined
  },
  q30: {
    question: 'Ženy',
    answer: undefined
  },
  q30a: {
    question: 'Jste těhotná',
    answer: undefined
  },
  q30b: {
    question: 'Porodila jste v posledních 6 měsících?',
    answer: undefined
  },
  q30c: {
    question: 'Kojíte nyní?',
    answer: undefined
  },
  q30d: {
    question: 'Měla jste v posledních 6 měsících potrat?',
    answer: undefined
  }
}

export const AP_ANNONYMOUS_BOOKING_TICKETS = [
  {
    name: 'Odběr krevní plazmy - jste náš stávající dárce',
    ticket_id: '8fed961f4f9dfa4f7935089f8e'
  },
  {
    name: 'Vstupní prohlídka - mám zájem darovat',
    ticket_id: '8cd7319da35d4c4ae53a128a9c'
  }
]

export const AP_YEARLY_CHECKUP_BOOKING_TICKET_ID = '86d4817fec4fda5a4c431a493b'

export const AP_BOOKING_TICKET_REGULAR_BLOOD_DONATION_ID = '8fed961f4f9dfa4f7935089f8e'

export const AP_BOOKING_STATUS_ARRIVED_ID = '8fed961f4f9dfa4f7935089f8e'
export const AP_BOOKING_STATUS_ARRIVED_ON_TIME_ID = '84de1207b5f6ca59f34f9dd4f2'
export const AP_BOOKING_STATUS_ACTIVE_ID = '81d1d16eb51f6a911de06f0435'
export const AP_BOOKING_STATUS_RESOLVED_ID = '87e05b9782dba69c0912c608e7'
export const AP_BOOKING_STATUS_CANCELED_ID = '8cd7319da35d4c4ae53a128a9c'
export const AP_CUSTOMER_SOURCE_ID = '89d1a607a35811f18c787d9d4c'
export const AP_ANNONYMOUS_CUSTOMER_SOURCE_ID = '87d5d7a433962adc2e960be104'
export const AP_AGREEMEMT_TYPE_ID = '87e05b9782dba69c0912c608e7'
export const AP_ADDITIONAL_ADDRESS_TYPE_ID = '87e05b9782dba69c0912c608e7'
export const AP_TIMELINE_EVENT_TYPE_ID = '8cc89f128a48808e4eb9721a86'
export const AP_SURVEY_EVENT_TYPE_ID = '8ec2961ae013889d8eb9d888c4'
export const AP_REFERENCE_OTHER_OPTION_ID = '81dab577ce1fb6a3337366c5ed'

export const AP_SURVEY_ALLOWED_SEGMENTS = [
  '87d5d7a433962adc2e960be104',
  '85dcb8ea54c493953db37d1ba8',
  '88d9095a513d05a104a8a01d4d'
]

export const AP_PRODUCT_RESERVATION_SOURCE_ID = {
  'darek_na_objednavku': '84de1207b5f6ca59f34f9dd4f2',
  'brandovy_produkt': '8fdb62b527c894d7de47fc554a',
  'voucher': '8ed8b2e9e836bfbd4e592630ac'
}

/**
 * 0 - Canceled
 * 1 - Entered
 * 2 - Accepted
 * 3 - Ready
 * 4 - Delivered
 * 5 - In progress
 * 6 - Not Picked up
 * 7 - Ordered
 * 8 - Being solved
 *
 */
export const API_RESERVATION_STATE = {
  0: 'Zrušená',
  1: 'Zadaná',
  2: 'Přijatá',
  3: 'Připravená',
  4: 'Doručená',
  5: 'Zpracovává se',
  6: 'Nevyzvednutá',
  7: 'Objednaná',
  8: 'V řešení'
}