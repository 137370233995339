import ReactComponent from "../app/ReactComponent";
import {Steps, Radio, Input, Button, Col, Space, Card, Result} from "antd";
import {AP_SURVEY_EVENT_TYPE_ID, AP_SURVEY_QUESTIONS} from "../helpers/misc";
import Query from "../app/Query";
import {PROPERTY_AP_SURVEY_KEY} from "../helpers/properties";
import React from "react";



function YesOrNoRadios(props) {
  return (
    <Radio.Group name={props.skey} value={props.questions[props.skey].answer} onChange={(data) => {
      props.setSurveyAnswer({[props.skey]: data.target.value})
    }}>
      <Radio value={true}>Ano</Radio>
      <Radio value={false}>Ne</Radio>
    </Radio.Group>
  )
}

function SurveyTextarea(props) {
  return (
    <div className="textarea-container">
      <label>{props.questions[props.skey].question}</label>
      <Input.TextArea rows={3} name={props.skey} value={props.questions[props.skey].answer} onChange={(data) => {
        props.setSurveyAnswer({[props.skey]: data.target.value})
      }}/>
    </div>
  )
}


export class Survey extends ReactComponent {

  state = {
    loading_page: false,
    current: 0,
    questions: AP_SURVEY_QUESTIONS,
    constants: {
      AP_SURVEY_EVENT_TYPE_ID
    }
  }

  async componentDidMount() {

    void this.getDataForForm()
  }

  async getDataForForm() {

    void this.updateState({loading_page: true})

    try {

      const {segment_records = []} = await this.$api({
        action: 'carecloud_user_api',
        route: 'segment-records'
      })

      const query = new Query('events')

      query.add('event_type_id', this.state.constants.AP_SURVEY_EVENT_TYPE_ID)
      query.add('sort_field', 'created_at')
      query.add('sort_direction', 'desc')

      const {events = []} = await this.$api({
        action: 'carecloud_api',
        route: query.route
      })

      const {event_id = false} = (events.at(0) ?? false)

      if (event_id) {

        const {property_records = []} = await this.$api({
          action: 'carecloud_api',
          route: `events/${event_id}/property-records`
        })

        const {property_value: survey_data = false} = (property_records.find(({property_id}) => property_id === PROPERTY_AP_SURVEY_KEY) ?? false)


        if (survey_data) {

          this.setSurveyData(JSON.parse(survey_data))

        }

      }

      void this.updateState({segments: segment_records})


    } catch (error) {

      console.log(error)

    } finally {

      void this.updateState({loading_page: false})

    }

  }

  setSurveyData(data) {
    let questions = {}

    data.forEach((item, index) => {

      let {answer} = item

      if (answer === "Ano") answer = true
      if (answer === "Ne") answer = false
      if (answer === "Nevyplněno") answer = undefined

      void this.updateState({[item.question]: answer})

      questions[item.question] = {
        ...this.state.questions[item.question],
        answer: answer
      }

    })

    this.setState((prevState) => ({
      ...prevState,
      questions: {
        ...prevState.questions,
        ...questions
      }
    }))

  }

  getSurveyData() {

    let result = []

    Object.keys(this.state.questions).forEach((item) => {

      let answer = this.state.questions[item].answer

      if (answer === true) answer = "Ano"
      if (answer === false) answer = "Ne"
      if (answer === undefined) answer = "Nevyplněno"

      result.push({question: item, answer: answer})

    })

    return result
  }

  setSurveyAnswer = async (question)=> {

    let key = Object.keys(question)[0] || null
    let value = question[key]

    await this.updateState(question)
    await this.updateState({
      questions: {
        ...this.state.questions,
        [key]: {
          ...this.state.questions[key],
          answer: value
        }
      }
    })

    /** Empty subquestions */
    if((value === false && key !== "q28") || (key === "q28" && value === true)) {

      let keysToBeEmpty = Object.keys(this.state.questions).filter((element) => (new RegExp(`^${key}[a-z][0-9a-z]{0,3}`)).test(element))

      keysToBeEmpty.forEach((item) => {

        this.setState((prevState) => ({
          ...prevState,
          [item]: undefined,
          questions: {
            ...prevState.questions,
            [item]: {
              ...prevState.questions[item],
              answer: undefined
            }
          }
        }))

      })

    }
  }

  changeSurveyStep = (data) => {
    void this.updateState({current: data})
  }

  nextStep = () => {
    const currentNextStep = this.state.current + 1
    void this.updateState({current: currentNextStep})
  }


  render() {
    return (<>
      <Steps
        direction="vertical"
        current={this.state.current}
        onChange={this.changeSurveyStep}
        items={[
          {
            className: this.state.questions.q1.answer !== undefined ? "step-open-by-default" : null,
            title: this.state.questions.q1.question,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q1 === true &&

                <>
                  <SurveyTextarea skey={"q1z1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
                  <SurveyTextarea skey={"q1z2"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
                </>

              }

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q2.question,
            className: this.state.questions.q2.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q2"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q3.question,
            className: this.state.questions.q3.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q3"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q3 === true &&
                <>
                  <SurveyTextarea skey={"q3z1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
                  <SurveyTextarea skey={"q3z2"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
                </>
              }
              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q4.question,
            className: this.state.questions.q4.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q4"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q4 === true &&

                <>
                  <SurveyTextarea skey={"q4z1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
                  <SurveyTextarea skey={"q4z2"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
                </>

              }
              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q5.question,
            className: this.state.questions.q5.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q5"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q6.question,
            className: this.state.questions.q6.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q6"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q6 === true &&

                <SurveyTextarea skey={"q6z"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              }

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q7.question,
            className: this.state.questions.q7.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q7"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q7 === true &&

                <>
                  <SurveyTextarea skey={"q7z1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
                  <SurveyTextarea skey={"q7z2"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
                </>

              }
              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q8.question,
            className: this.state.questions.q8.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q8"} questions={this.state.questions} setSurveyAnswer={(...args) => {
                this.setSurveyAnswer(...args)
              }}/>

              {this.state.q8 === true &&

                <SurveyTextarea skey={"q8z"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              }
              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q9.question,
            className: this.state.questions.q9.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q9"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q10.question,
            className: this.state.questions.q10.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q10"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q10 === true &&

                <SurveyTextarea skey={"q10z"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              }

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q11.question,
            className: this.state.questions.q11.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q11"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q11 === true &&

                <>
                  <SurveyTextarea skey={"q11z1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

                  <SurveyTextarea skey={"q11z2"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
                </>

              }

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q12.question,
            className: (this.state.questions.q12a.answer !== undefined ||
              this.state.questions.q12b.answer !== undefined ||
              this.state.questions.q12c.answer !== undefined ||
              this.state.questions.q12d.answer !== undefined

            ) ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <div>
                <label>{this.state.questions.q12a.question}</label>
                <YesOrNoRadios skey={"q12a"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>

              <div>
                <label>{this.state.questions.q12b.question}</label>
                <YesOrNoRadios skey={"q12b"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>

              <div>
                <label>{this.state.questions.q12c.question}</label>
                <YesOrNoRadios skey={"q12c"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>

              <div>
                <label>{this.state.questions.q12d.question}</label>
                <YesOrNoRadios skey={"q12d"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q13.question,
            className: this.state.questions.q13.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q13"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q13 === true &&

                <SurveyTextarea skey={"q13z"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              }

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q14.question,
            className: this.state.questions.q14.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q14"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q15.question,
            className: this.state.questions.q15.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q15"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q16.question,
            className: this.state.questions.q16.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q16"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q17.question,
            className: this.state.questions.q17.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q17"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q18.question,
            className: this.state.questions.q18.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q18"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q19.question,
            className: this.state.questions.q19.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q19"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q19 === true &&

                <SurveyTextarea skey={"q19z"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              }

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q20.question,
            className: this.state.questions.q20.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q20"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q21.question,
            className: this.state.questions.q21.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q21"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q22.question,
            className: this.state.questions.q22.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q22"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q23.question,
            className: this.state.questions.q23.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q23"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q24.question,
            className: this.state.questions.q24.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q24"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q24 === true &&

                <SurveyTextarea skey={"q24z"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              }

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q25.question,
            className: this.state.questions.q25.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q25"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q26.question,
            className: (this.state.questions.q26a.answer !== undefined ||
              this.state.questions.q26b.answer !== undefined ||
              this.state.questions.q26c.answer !== undefined ||
              this.state.questions.q26d.answer !== undefined ||
              this.state.questions.q26e.answer !== undefined ||
              this.state.questions.q26f.answer !== undefined ||
              this.state.questions.q26g.answer !== undefined ||
              this.state.questions.q26i.answer !== undefined ||
              this.state.questions.q26j.answer !== undefined ||
              this.state.questions.q26k.answer !== undefined ||
              this.state.questions.q26l.answer !== undefined ||
              this.state.questions.q26m.answer !== undefined ||
              this.state.questions.q26n.answer !== undefined ||
              this.state.questions.q26o.answer !== undefined ||
              this.state.questions.q26p.answer !== undefined ||
              this.state.questions.q26q.answer !== undefined ||
              this.state.questions.q26r.answer !== undefined ||
              this.state.questions.q26s.answer !== undefined ||
              this.state.questions.q26t.answer !== undefined ||
              this.state.questions.q26u.answer !== undefined ||
              this.state.questions.q26v.answer !== undefined ||
              this.state.questions.q26w.answer !== undefined ||
              this.state.questions.q26x.answer !== undefined ||
              this.state.questions.q26y.answer !== undefined
            ) ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <div className="q26-subtitle">a) Mozek nebo nervový systém</div>
              <div className="mi-3">
                <label>{this.state.questions.q26a.question}</label>
                <YesOrNoRadios skey={"q26a"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.state.questions.q26b.question}</label>
                <YesOrNoRadios skey={"q26b"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.state.questions.q26c.question}</label>
                <YesOrNoRadios skey={"q26c"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.state.questions.q26d.question}</label>
                <YesOrNoRadios skey={"q26d"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.state.questions.q26e.question}</label>
                <YesOrNoRadios skey={"q26e"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.state.questions.q26f.question}</label>
                <YesOrNoRadios skey={"q26f"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.state.questions.q26g.question}</label>
                <YesOrNoRadios skey={"q26g"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">b) Srdce a krevní oběh</div>
              <div className="mi-3">
                <label>{this.state.questions.q26i.question}</label>
                <YesOrNoRadios skey={"q26i"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">c) Zhoubné nádory</div>
              <div className="mi-3">
                <label>{this.state.questions.q26j.question}</label>
                <YesOrNoRadios skey={"q26j"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">d) Dýchací orgány</div>
              <div className="mi-3">
                <label>{this.state.questions.q26k.question}</label>
                <YesOrNoRadios skey={"q26k"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">e) Zažívací ústrojí</div>
              <div className="mi-3">
                <label>{this.state.questions.q26l.question}</label>
                <YesOrNoRadios skey={"q26l"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">f) Močové cesty a pohlavní orgány</div>
              <div className="mi-3">
                <label>{this.state.questions.q26m.question}</label>
                <YesOrNoRadios skey={"q26m"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.state.questions.q26n.question}</label>
                <YesOrNoRadios skey={"q26n"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">g) Žloutenka nebo jiné onemocnění jater</div>
              <div className="mi-3">
                <label>{this.state.questions.q26o.question}</label>
                <YesOrNoRadios skey={"q26o"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">h) Malárie nebo jiná tropická onemocnění</div>
              <div className="mi-3">
                <label>{this.state.questions.q26p.question}</label>
                <YesOrNoRadios skey={"q26p"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">i) Jiné nakažlivé onemocnění (případně kontakt s nimi)</div>
              <div className="mi-3">
                <label>{this.state.questions.q26q.question}</label>
                <YesOrNoRadios skey={"q26q"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">j) Jiná infekční a horečnatá onemocnění, covid 19</div>
              <div className="mi-3">
                <label>{this.state.questions.q26r.question}</label>
                <YesOrNoRadios skey={"q26r"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

                {this.state.q26r === true &&

                  <div className="mi-3">
                    <label>{this.state.questions.q26rz.question}</label>
                    <YesOrNoRadios skey={"q26rz"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
                  </div>

                }

              </div>

              <div className="mi-3">
                <label>{this.state.questions.q26s.question}</label>
                <YesOrNoRadios skey={"q26s"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">k) Onemocnění štítné žlázy nebo slinivky břišní</div>
              <div className="mi-3">
                <label>{this.state.questions.q26t.question}</label>
                <YesOrNoRadios skey={"q26t"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">l) Poruchy krevní srážlivosti, krvácivosti nebo trombozy</div>
              <div className="mi-3">
                <label>{this.state.questions.q26u.question}</label>
                <YesOrNoRadios skey={"q26u"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.state.questions.q26v.question}</label>
                <YesOrNoRadios skey={"q26v"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">m) Kožní onemocnění</div>
              <div className="mi-3">
                <label>{this.state.questions.q26w.question}</label>
                <YesOrNoRadios skey={"q26w"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">n) Poruchy látkové výmeny, např. cukrovka</div>
              <div className="mi-3">
                <label>{this.state.questions.q26x.question}</label>
                <YesOrNoRadios skey={"q26x"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">o) Zánětlivý revmatizmus např. polyartritida nebo jiné autoimunitní onemocnění
              </div>
              <div className="mi-3">
                <label>{this.state.questions.q26y.question}</label>
                <YesOrNoRadios skey={"q26y"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q27.question,
            className: (this.state.questions.q27a.answer !== undefined ||
              this.state.questions.q27b.answer !== undefined ||
              this.state.questions.q27c.answer !== undefined
            ) ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <div>
                <label>{this.state.questions.q27a.question}</label>
                <YesOrNoRadios skey={"q27a"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

                {this.state.q27a === true &&

                  <SurveyTextarea skey={"q27az"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

                }

              </div>
              <div>
                <label>{this.state.questions.q27b.question}</label>
                <YesOrNoRadios skey={"q27b"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

                {this.state.q27b === true &&

                  <SurveyTextarea skey={"q27bz"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

                }

              </div>
              <div>
                <label>{this.state.questions.q27c.question}</label>
                <YesOrNoRadios skey={"q27c"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

                {this.state.q27c === true &&

                  <SurveyTextarea skey={"q27cz"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

                }

              </div>
              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q28.question,
            className: this.state.questions.q28.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q28"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              {this.state.q28 === false && <>
                <div>
                  <label>{this.state.questions.q28a.question}</label>
                  <YesOrNoRadios skey={"q28a"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
                </div>
                <div>
                  <label>{this.state.questions.q28b.question}</label>
                  <YesOrNoRadios skey={"q28b"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
                  {this.state.q28b === true &&

                    <SurveyTextarea skey={"q28z"} questions={this.state.questions} setSurveyAnswer={(...args) => {
                      this.setSurveyAnswer(...args)
                    }}/>

                  }
                </div>
              </>}
              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q29.question,
            className: this.state.questions.q29.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q29"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              {this.state.q29 === true && <>

                <SurveyTextarea skey={"q29z"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              </>}
              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.state.questions.q30.question,
            className: (this.state.questions.q30a.answer !== undefined ||
              this.state.questions.q30b.answer !== undefined ||
              this.state.questions.q30c.answer !== undefined ||
              this.state.questions.q30d.answer !== undefined
            ) ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <div>
                <label>{this.state.questions.q30a.question}</label>
                <YesOrNoRadios skey={"q30a"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div>
                <label>{this.state.questions.q30b.question}</label>
                <YesOrNoRadios skey={"q30b"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div>
                <label>{this.state.questions.q30c.question}</label>
                <YesOrNoRadios skey={"q30c"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div>
                <label>{this.state.questions.q30d.question}</label>
                <YesOrNoRadios skey={"q30d"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
            </div>
          },
        ]}
      />
    </>)
  }
}